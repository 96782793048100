import {Toast, Tab} from 'bootstrap'
import '@fortawesome/fontawesome-free/scss/fontawesome.scss'
import '@fortawesome/fontawesome-free/scss/brands.scss'
import '@fortawesome/fontawesome-free/scss/regular.scss'
import '@fortawesome/fontawesome-free/scss/solid.scss'
import '@fortawesome/fontawesome-free/scss/v4-shims.scss'
import {tooltips} from './helpers'

/**
 * Loads all bootstrap tooltips
 */
tooltips()

/**
 * Convertir un número en un texto según el formato especificado
 * @param {number} c cantidad de decimales
 * @param {string} d separador decimal
 * @param {string} t separador milésimo
 * @return {string} el número escrito según el formato especificado
 */
Number.prototype.formatNumber = function(c, d, t){
	c = isNaN(c = Math.abs(c)) ? 2 : c
	d = d === undefined ? "." : d
	t = t === undefined ? "," : t
	let n = this,
		s = n < 0 ? "-" : "",
		i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
		j = i.length > 3 ? i.length % 3 : 0
	return s + (j ? i.substring(0, j) + t : "") + i.substring(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "")
}

/**
 * Redirects to the given path
 * @param {string} path the path to follow
 */
Window.prototype.redirect = function(path){
	window.location.assign(path)
}

/**
 * Shows the toast passed by argument
 * @param {{animation: boolean, autohide: boolean, delay: number}} options the options to be used. Defaults: {animation: true, autohide: true, delay: 5000}
 */
Window.prototype.toast = function(options){
	let toastElList = [].slice.call(document.getElementsByClassName('toast'))
	toastElList.map(function(el){
		let toastEl = new Toast(el, options)
		toastEl.show()
	})
}

/**
 * Show a success-themed toast
 * @param {string} text the text to show
 * @param {boolean} close `TRUE` if the toast should be closed after 3 seconds. `FALSE` by default.
 */
Window.prototype.showSuccess = function(text, close = false){
	document.getElementById('msg').innerHTML =
		'<div class="toast w-100" role="alert" aria-live="assertive" aria-atomic="true">' +
		'<div class="toast-header bg-success text-white">' +
		'<strong class="me-auto"><i class="fa-solid fa-check me-2"></i>Success</strong>' +
		'<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>' +
		'</div>' +
		'<div class="toast-body">' + text + '</div>' +
		'</div>'
	this.toast({
		animation: true,
		autohide: close,
		delay: 5000
	})
}

/**
 * Show an info-themed toast
 * @param {string} text the text to show
 * @param {boolean} close `TRUE` if the toast should be closed after 3 seconds. `FALSE` by default.
 */
Window.prototype.showInfo = function(text, close = false){
	document.getElementById('msg').innerHTML =
		'<div class="toast w-100" role="alert" aria-live="assertive" aria-atomic="true">' +
		'<div class="toast-header bg-info text-white">' +
		'<strong class="me-auto"><i class="fa-solid fa-info-circle me-2"></i>Information</strong>' +
		'<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>' +
		'</div>' +
		'<div class="toast-body">' + text + '</div>' +
		'</div>'
	this.toast({
		animation: true,
		autohide: close,
		delay: 5000
	})
}

/**
 * Show a danger-themed toast
 * @param {string} text the text to show
 */
Window.prototype.showError = function(text){
	document.getElementById('msg').innerHTML +=
		'<div class="toast w-100" role="alert" aria-live="assertive" aria-atomic="true">' +
		'<div class="toast-header bg-danger text-white">' +
		'<strong class="me-auto"><i class="fa-solid fa-exclamation-circle me-2"></i>Error</strong>' +
		'<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>' +
		'</div>' +
		'<div class="toast-body">' + text + '</div>' +
		'</div>'
}

/**
 * Show a warning-themed toast
 * @param {string} text the text to show
 * @param {boolean} close `TRUE` if the toast should be closed after 3 seconds. `FALSE` by default.
 */
Window.prototype.showWarning = function(text, close = false){
	document.getElementById('msg').innerHTML =
		'<div class="toast w-100" role="alert" aria-live="assertive" aria-atomic="true">' +
		'<div class="toast-header bg-warning text-white">' +
		'<strong class="me-auto"><i class="fa-solid fa-exclamation-triangle me-2"></i>Warning</strong>' +
		'<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>' +
		'</div>' +
		'<div class="toast-body">' + text + '</div>' +
		'</div>'
	this.toast({
		animation: true,
		autohide: close,
		delay: 5000
	})
}

/**
 * Show Laravel validation errors in the form of error toasts
 * @param {{response: {status: number, data: {message: string, errors: JSON}}}|string} error
 * @param {boolean} close `TRUE` if the toast should be closed after 3 seconds. `FALSE` by default.
 */
Window.prototype.showValidationErrors = function(error, close = false){
	document.getElementById('msg').innerHTML = ''
	if(error.response){
		window.showError(error.response.status + ': ' + error.response.data.message)
		let errors = error.response.data.errors
		for(let i in errors){
			for(let j = 0; j < errors[i].length; j++){
				window.showError(errors[i][j])
			}
		}
	} else {
		window.showError(error)
	}
	window.toast({
		animation: true,
		autohide: close,
		delay: 5000
	})
}

/**
 * Selects the valued tab
 * @param {string} prefix the tab prefix
 * @param {number|string} value the value
 * @param {string} suffix the tab suffix
 */
Window.prototype.select_tab = function(prefix, value, suffix = ''){
	Tab.getOrCreateInstance(document.getElementById(prefix + value + suffix)).show()
}
